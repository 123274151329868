import React, { useState } from "react";
import logoDark from "../../assets/logo.png";
import logoLight from "../../assets/logo_light.png";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@react-hook/media-query";
import { UserAuth } from "../../context/authContext";

const Navbar = () => {
  const { user } = UserAuth();
  const { logout } = UserAuth();
  const navigate = useNavigate();
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const logo = prefersDarkMode ? logoDark : logoLight;
  const userName =
    user && user.displayName ? user.displayName.split(" ")[0] : "Guest";
  const userProfile = user && user.photoURL ? user.photoURL : "";
  const userInitials = user && user.email ? user.email.substring(0, 2) : "";
  const profileBackgroundColors = [
    "#A9CCE3",
    "#D5D8DC",
    "#ABEBC6",
    "#F5CBA7",
    "#FADBD8",
    "#D7BDE2",
  ];
  const userBackground = profileBackgroundColors[Math.floor(Math.random() * 6)];

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/");
    } catch (e) {
      console.error("Logout failed", e);
    }
  };

  return (
    <nav className="bg-white border-gray-200 dark:bg-gray-900 sticky top-0 z-20">
      <div className="max-w-screen-xl flex justify-between items-center mx-auto md:py-4 md:px-10 py-4 px-4">
        <div className="flex items-center space-x-6 rtl:space-x-reverse">
          <a
            href="/"
            className="flex items-center space-x-3 rtl:space-x-reverse"
          >
            <img
              src={logo}
              className="h-8 w-8 rounded-full bg-transparent"
              alt="MonkeyMonk Logo"
            />
            <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">
              MonkeyMonk
            </span>
          </a>
        </div>
        <div className="relative">
          {userProfile ? (
            <button
              onClick={toggleDropdown}
              className="flex items-center text-gray-700 font-medium bg-white border border-primary-600 rounded-full px-2 py-2 hover:bg-primary-50 shadow-sm transition"
            >
              <div
                className="w-6 h-6 rounded-full bg-cover bg-center"
                style={{
                  backgroundImage: `url(${userProfile})`,
                }}
              >
                <i className="fa-solid fa-circle text-xs text-green-500 absolute right-0 bottom-0"></i>
              </div>
              <span className="ml-3">{userName}</span>
              <i className="fa-solid fa-angle-down ml-2"></i>
            </button>
          ) : (
            <div
              onClick={toggleDropdown}
              className="w-12 h-12 rounded-full flex justify-center items-center cursor-pointer"
              style={{ backgroundColor: userBackground }}
            >
              <h3 className="font-bold">{userInitials.toUpperCase()}</h3>
            </div>
          )}
          {isDropdownOpen && (
            <div
              className="absolute right-0 mt-2 w-48 flex-col bg-white drop-shadow-lg rounded-lg z-10"
              onClick={closeDropdown}
            >
              <a
                className="px-5 py-3 flex items-center text-gray-700 cursor-pointer"
                onClick={handleLogout}
              >
                <i className="fa-solid fa-arrow-right-from-bracket mr-3"></i>
                Log out
              </a>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
