import React, { useEffect, useState } from 'react'
import { UserAuth } from '../../context/authContext';
import { Navigate, useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from "../../firebase/Firebase";


const ZenoraLayout = ({children}) => {
  const navigate = useNavigate();
  const { user } = UserAuth();
  const currentUser = user;

  const [completeUserData , setCompleteUserData] = useState();

  const fetchCompleteUserDetails = async () => {
    const userDocRef = doc(db, "users", currentUser?.uid);
    try {
      const userDocSnap = await getDoc(userDocRef);

      if (userDocSnap.exists()) {
        const user = userDocSnap.data();
        setCompleteUserData(user);
      }
    } catch (error) {   
    }
  };
    useEffect(() => {
      if(currentUser?.uid)
        fetchCompleteUserDetails();
  }, [currentUser]);

    if(user){
      return (
        <div className="flex ">
          <main className="h-screen flex-1">{children}</main>
        </div>
      );
    }
   
    else{
        return <Navigate to='/login' />
    }
}

export default ZenoraLayout