import { useLayoutEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  sendPasswordResetEmail,
  signOut,
  getUserByEmail,
} from "firebase/auth";
import { auth, db } from "../../firebase/Firebase";
import { FcGoogle } from "react-icons/fc";
import { IoEye, IoEyeOff } from "react-icons/io5";
import logo from "../../assets/logo.png";
import { collection, getDocs, query, where } from "firebase/firestore";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./Login.css";
import { UserAuth } from "../../context/authContext";

const Login = () => {
  const navigate = useNavigate();
  const { user } = UserAuth();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const [resetEmailSent, setResetEmailSent] = useState(false);

  const validateForm = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const newErrors = {};
    if (!email.trim() || !emailRegex.test(email)) {
      newErrors.email = "Invalid email address";
    }
    if (password.length < 6) {
      newErrors.password = "Password must be at least 6 characters";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const signInWithGoogle = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const usersRef = collection(db, "users");
      const q = query(usersRef, where("email", "==", user.email));
      const querySnapshot = await getDocs(q);
      const userExists = !querySnapshot.empty;
      if (userExists) {
        navigate("/zenora");
      } else {
        await signOut(auth);
        navigate("/register");
        setTimeout(() => {
          showToastMessage("User doesn't exist. Please register.","error");
        }, 1000);
      }
    } catch (error) {
      console.error("Error signing in with Google:", error.message);
    }
  };

  const showToastMessage = (toastMessage, toastType) => {
    if(toastType == "success"){
      toast.success(toastMessage, {
        position: "bottom-right",
      });
    }
    else{
      toast.error(toastMessage, {
        position: "bottom-right",
      });
    }
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    setLoading(true);
    try {
      if (validateForm()) {
        const userCredential = await signInWithEmailAndPassword(
          auth,
          email,
          password
        );
        const user = userCredential.user;
        if (user) {
          navigate("/zenora");
        } else {
          navigate("/register");
        }
        setErrors({});
      }
    } catch (error) {
      console.error("Error logging in user:", error.code, error.message);
      if (
        error.code === "auth/user-not-found" ||
        error.code === "auth/wrong-password"
      ) {
        setErrors({ authError: "Incorrect username or password." });
      } else {
        setErrors({ authError: "An error occurred. Please try again later." });
      }
      setEmail("");
      setPassword("");
    }
    setLoading(false);
  };

  const handleResetPassword = async () => {
    setResetEmailSent(false);
    if (email) {
      try {
        const usersRef = collection(db, "users");
        const q = query(usersRef, where("email", "==", email));
        const querySnapshot = await getDocs(q);
        const userExists = !querySnapshot.empty;
        if (!userExists) {
          showToastMessage("User doesn't exist please register","error");
        } else {
          await sendPasswordResetEmail(auth, email);
          setResetEmailSent(true);
        }
      } catch (error) {
        if (error.code === "auth/user-not-found") {
          console.error("User not found. Unable to send reset password email.");
        } else {
          console.error("Error sending reset password email:", error.message);
        }
        setResetEmailSent(false);
      }
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const newErrors = {};
      if (!email.trim() || !emailRegex.test(email)) {
        newErrors.email = "Invalid email address";
        setErrors(newErrors);
      }
    }
  };

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const textRef = useRef(null);

  const sentences = [
    "To Travel is to Live !",
    "Travel is the Best education. You learn about Culture, History and yourself.",
    "Travel allows you to escape ordinary and embrace the extraordinary.",
    "Travel Opens your Heart, Broadens your mind, and fills your life with stories to tell.",
    "Travel is the only thing which you can spend that makes you richer.!",
  ];
  let currentSentenceIndex = 0;
  let currentLetterIndex = 0;
  let intervalId;

  useLayoutEffect(() => {
    const animateText = () => {
      intervalId = setInterval(() => {
        const currentSentence = sentences[currentSentenceIndex];
        const currentText = currentSentence.slice(0, currentLetterIndex);
        if (textRef.current) {
          textRef.current.textContent = currentText;
        }

        currentLetterIndex++;

        if (currentLetterIndex > currentSentence.length) {
          clearInterval(intervalId);
          currentLetterIndex = 0;
          currentSentenceIndex = (currentSentenceIndex + 1) % sentences.length;
          setTimeout(animateText, 2000);
        }
      }, 70);
    };

    animateText();

    return () => clearInterval(intervalId);
  }, [textRef.current]);

  return (
    <>
      <main className="w-full h-screen flex flex-col items-center justify-center px-4">
        <div className="max-w-sm w-full text-gray-600 space-y-5">
          <div className="text-center pb-8">
            <div className="flex justify-center items-center space-x-4">
              <div className="w-12 h-12 bg-slate-800 rounded-full p-2">
                <img src={logo} alt="logo" className="" />
              </div>
            </div>
            <div className="mt-5">
              <h3 className="text-gray-800 text-2xl font-bold sm:text-3xl">
                Welcome back
              </h3>
              {errors.authError && (
                <span className="ml-2 text-red-500">{errors.authError}</span>
              )}
            </div>
          </div>
          <form onSubmit={handleSubmit} className="space-y-5">
            {resetEmailSent && (
              <span className="ml-2 text-blue-500 font-semibold">
                Password reset link has been shared to your email
              </span>
            )}
            <div>
              <label className="font-medium">Email</label>
              <input
                type="email"
                required
                className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-primary-600 shadow-sm rounded-lg"
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                value={email}
              />
            </div>
            {errors.email && (
              <span className="text-red-500">{errors.email}</span>
            )}
            <div className="relative">
              <div>
                <label className="font-medium">Password</label>
                <input
                  className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-primary-600 shadow-sm rounded-lg"
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <div
                className="absolute top-0 right-0 mt-11 mr-4 cursor-pointer"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? <IoEye /> : <IoEyeOff />}
              </div>
            </div>
            <div className="text-sm">
              <p
                className="text-center text-primary-600 hover:text-primary-500 cursor-pointer"
                onClick={handleResetPassword}
              >
                Forgot password?
              </p>
            </div>
            <button
              className="w-full px-4 py-2 text-white font-medium bg-primary-600 hover:bg-primary-500 active:bg-primary-600 rounded-lg duration-150"
              type="submit"
            >
              Sign in
            </button>
          </form>
          <button
            className="w-full flex items-center justify-center gap-x-3 py-2.5 border rounded-lg text-sm font-medium hover:bg-gray-50 duration-150 active:bg-gray-100"
            onClick={signInWithGoogle}
          >
            <svg
              className="w-5 h-5"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_17_40)">
                <path
                  d="M47.532 24.5528C47.532 22.9214 47.3997 21.2811 47.1175 19.6761H24.48V28.9181H37.4434C36.9055 31.8988 35.177 34.5356 32.6461 36.2111V42.2078H40.3801C44.9217 38.0278 47.532 31.8547 47.532 24.5528Z"
                  fill="#4285F4"
                />
                <path
                  d="M24.48 48.0016C30.9529 48.0016 36.4116 45.8764 40.3888 42.2078L32.6549 36.2111C30.5031 37.675 27.7252 38.5039 24.4888 38.5039C18.2275 38.5039 12.9187 34.2798 11.0139 28.6006H3.03296V34.7825C7.10718 42.8868 15.4056 48.0016 24.48 48.0016Z"
                  fill="#34A853"
                />
                <path
                  d="M11.0051 28.6006C9.99973 25.6199 9.99973 22.3922 11.0051 19.4115V13.2296H3.03298C-0.371021 20.0112 -0.371021 28.0009 3.03298 34.7825L11.0051 28.6006Z"
                  fill="#FBBC04"
                />
                <path
                  d="M24.48 9.49932C27.9016 9.44641 31.2086 10.7339 33.6866 13.0973L40.5387 6.24523C36.2 2.17101 30.4414 -0.068932 24.48 0.00161733C15.4055 0.00161733 7.10718 5.11644 3.03296 13.2296L11.005 19.4115C12.901 13.7235 18.2187 9.49932 24.48 9.49932Z"
                  fill="#EA4335"
                />
              </g>
              <defs>
                <clipPath id="clip0_17_40">
                  <rect width="48" height="48" fill="white" />
                </clipPath>
              </defs>
            </svg>
            Continue with Google
          </button>
          <p className="text-center">
            Don't have an account?{" "}
            <Link
              to="/register"
              className=" underline cursor-pointer text-primary-400 ml-2"
            >
              Sign Up
            </Link>
          </p>
        </div>
      </main>
      <ToastContainer />
    </>
  );
};

export default Login;
