import React, { useLayoutEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import {
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  GoogleAuthProvider,
  sendEmailVerification,
  signInWithPopup,
  updateProfile,
} from "firebase/auth";
import { auth, db } from "../../firebase/Firebase";
import { doc, setDoc, collection, query, where, getDocs } from "firebase/firestore";

import { FcGoogle } from "react-icons/fc";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import logo from "../../assets/logo.png";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UserAuth } from "../../context/authContext";
import { IoEye, IoEyeOff } from "react-icons/io5";

const Registration = () => {
  const navigate = useNavigate();
  const { user } = UserAuth();

  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    password: "",
    confirmPassword: "",
    photoURL: "",
  });
  const [errors, setErrors] = useState({
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [signupError, setSignupError] = useState("");
  const [signupGoogleError, setSignupGoogleError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [termsAccepted, setTermsAccepted] = useState(false);

  const handleCheckboxChange = () => {
    if (!termsAccepted) {
      setSignupError("");
      setSignupGoogleError("");
    }
    setTermsAccepted(!termsAccepted);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const signUpUser = async (email, password) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      await sendEmailVerification(user);
      showToastMessage(
        "Registration successful. Please check your email for verification.","success"
      );
      return user;
    } catch (error) {
      console.error("Error signing up user:", error.message);
      if (error.code === "auth/email-already-in-use") {
        showToastMessage("Email address is already in use.","error")
      } else if (error.code === "auth/weak-password") {
        setSignupError("Password is too weak.");
      } else {
        setSignupError("An error occurred during sign up.");
      }
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = {};
    if (!termsAccepted) {
      setSignupError("Please accept the terms and conditions to sign up.");
      return;
    }

    if (!formData.email) {
      newErrors.email = "Email is required";
    }

    if (!formData.password) {
      newErrors.password = "Password is required";
    } else if (
      !/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}/.test(
        formData.password
      )
    ) {
      newErrors.password =
        "Password must contain at least 6 characters, one uppercase letter, one digit, and one special character";
    }

    if (!formData.confirmPassword) {
      newErrors.confirmPassword = "Confirm Password is required";
    }

    if (formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    } else {
      setErrors({});
    }

    try {
      const User = await signUpUser(formData.email, formData.password);
      const userId = User.uid;
      const docRef = doc(db, "users", userId);
      await setDoc(docRef, {
        userId: userId,
        email: formData.email,
        fullName: "",
        photoURL: "",
      });

      navigate("/verifyEmail");
    } catch (error) {
      console.error("Error registering user:", error.message);
    }
  };

  const signUpWithGoogle = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
  
      // Check if a user with this email already exists
      const usersRef = collection(db, "users");
      const q = query(usersRef, where("email", "==", user.email));
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        showToastMessage("User already registered with this email please login.", "error");
        return;
      }
  
      const userData = {
        userId: user.uid,
        fullName: user.displayName,
        email: user.email,
        photoURL: user.photoURL || "",
      };
  
      const userDocRef = doc(db, "users", user.uid);
      await setDoc(userDocRef, userData);
  
      showToastMessage("Registration with Google successful!", "success");
      navigate("/zenora");
    } catch (error) {
      showToastMessage("Error signing up with Google: " + error.message, "error");
    }
  };

  const showToastMessage = (toastMessage, toastType) => {
    if(toastType == "success"){
      toast.success(toastMessage, {
        position: "bottom-right",
      });
    }
    else{
      toast.error(toastMessage, {
        position: "bottom-right",
      });
    }
  };

  const textRef = useRef("");

  const sentences = [
    "To Travel is to Live !",
    "Travel is the Best education. You learn about Culture, History and yourself.",
    "Travel allows you to escape ordinary and embrace the extraordinary.",
    "Travel Opens your Heart, Broadens your mind, and fills your life with stories to tell.",
    "Travel is the only thing which you can spend that makes you richer.!",
  ];
  let currentSentenceIndex = 0;
  let currentLetterIndex = 0;
  let intervalId;

  useLayoutEffect(() => {
    const animateText = () => {
      intervalId = setInterval(() => {
        const currentSentence = sentences[currentSentenceIndex];
        const currentText = currentSentence.slice(0, currentLetterIndex);
        if (textRef.current) {
          textRef.current.textContent = currentText;
        }

        currentLetterIndex++;

        if (currentLetterIndex > currentSentence.length) {
          clearInterval(intervalId);
          currentLetterIndex = 0;
          currentSentenceIndex = (currentSentenceIndex + 1) % sentences.length;
          setTimeout(animateText, 2000);
        }
      }, 70);
    };

    animateText();

    return () => clearInterval(intervalId);
  }, [textRef.current]);

  return (
    <div className="w-full min-h-screen flex flex-col items-center justify-center px-4">
      <div className="max-w-sm w-full text-gray-600 space-y-5">
        <div className="text-center w-full flex justify-center items-center">
          <div className="w-16 h-16 bg-slate-800 rounded-full mx-4 p-2">
            <img src={logo} alt="logo" className="" />
          </div>
        </div>
        <div className="mt-12 flex flex-col items-center">
          <h1 className="text-2xl xl:text-3xl font-extrabold">
            Sign up for MonkeyMonk
          </h1>
          <div className="w-full flex-1 mt-8 space-y-5">
            <form className="space-y-5" onSubmit={handleSubmit}>
              <div className="relative">
                <label className="font-medium">Email</label>
                <input
                  className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-primary-600 shadow-sm rounded-lg"
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                />
                {errors.email && (
                  <span className="text-red-500">{errors.email}</span>
                )}
              </div>
              <div className="relative">
                <label className="font-medium">Password</label>
                <input
                  className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-primary-600 shadow-sm rounded-lg"
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter Password"
                  value={formData.password}
                  name="password"
                  onChange={handleChange}
                />
                <div
                  className="absolute top-0 right-0 mt-11 mr-4 cursor-pointer"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? <IoEye /> : <IoEyeOff />}
                </div>
              </div>
              {errors.password && (
                <span className="text-red-500">{errors.password}</span>
              )}

              <div className="relative">
                <label className="font-medium">Confirm Password</label>
                <input
                  className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-primary-600 shadow-sm rounded-lg"
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="Confirm Password"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                />
                <div
                  className="absolute top-0 right-0 mt-11 mr-4 cursor-pointer"
                  onClick={toggleConfirmPasswordVisibility}
                >
                  {showPassword ? <IoEye /> : <IoEyeOff />}
                </div>
              </div>
              {errors.confirmPassword && (
                <span className="text-red-500">{errors.confirmPassword}</span>
              )}
              <button className="w-full px-4 py-2 text-white font-medium bg-primary-600 hover:bg-primary-500 active:bg-primary-600 rounded-lg duration-150">
                <span className="ml-3">Sign Up</span>
              </button>
              {signupError && (
                <span className="text-red-500 w-3/4">{signupError}</span>
              )}
            </form>
            <button
              className="w-full flex items-center justify-center gap-x-3 py-2.5 border rounded-lg text-sm font-medium hover:bg-gray-50 duration-150 active:bg-gray-100"
              onClick={() => {
                if (!termsAccepted) {
                  setSignupGoogleError(
                    "Please accept the terms and conditions to sign up."
                  );
                } else {
                  signUpWithGoogle();
                }
              }}
            >
              <svg
                className="w-5 h-5"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_17_40)">
                  <path
                    d="M47.532 24.5528C47.532 22.9214 47.3997 21.2811 47.1175 19.6761H24.48V28.9181H37.4434C36.9055 31.8988 35.177 34.5356 32.6461 36.2111V42.2078H40.3801C44.9217 38.0278 47.532 31.8547 47.532 24.5528Z"
                    fill="#4285F4"
                  />
                  <path
                    d="M24.48 48.0016C30.9529 48.0016 36.4116 45.8764 40.3888 42.2078L32.6549 36.2111C30.5031 37.675 27.7252 38.5039 24.4888 38.5039C18.2275 38.5039 12.9187 34.2798 11.0139 28.6006H3.03296V34.7825C7.10718 42.8868 15.4056 48.0016 24.48 48.0016Z"
                    fill="#34A853"
                  />
                  <path
                    d="M11.0051 28.6006C9.99973 25.6199 9.99973 22.3922 11.0051 19.4115V13.2296H3.03298C-0.371021 20.0112 -0.371021 28.0009 3.03298 34.7825L11.0051 28.6006Z"
                    fill="#FBBC04"
                  />
                  <path
                    d="M24.48 9.49932C27.9016 9.44641 31.2086 10.7339 33.6866 13.0973L40.5387 6.24523C36.2 2.17101 30.4414 -0.068932 24.48 0.00161733C15.4055 0.00161733 7.10718 5.11644 3.03296 13.2296L11.005 19.4115C12.901 13.7235 18.2187 9.49932 24.48 9.49932Z"
                    fill="#EA4335"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_17_40">
                    <rect width="48" height="48" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              Sign up with Google
            </button>
            {signupGoogleError && (
              <span className="text-red-500 w-full max-w-xs">
                {signupGoogleError}
              </span>
            )}
            <div className="flex justify-center my-4">
              <input
                id="checkbox-1"
                aria-describedby="checkbox-1"
                type="checkbox"
                className="bg-gray-50 mt-1 border-gray-300 focus:ring-3 focus:ring-blue-300 h-4 w-4 rounded"
                checked={termsAccepted}
                onChange={handleCheckboxChange}
                required
              />
              <label
                htmlFor="checkbox-1"
                className="text-sm ml-3 font-medium text-gray-900"
              >
                I agree to the
                <span
                  className="text-blue-600 hover:underline ml-1 cursor-pointer"
                  onClick={() => {
                    navigate("/terms&conditions");
                  }}
                >
                  terms of service
                </span>{" "}
                and{" "}
                <span
                  className="text-blue-600 hover:underline cursor-pointer"
                  onClick={() => {
                    navigate("/privacypolicy");
                  }}
                >
                  privacy policy
                </span>
              </label>
            </div>
            <div className="text-center">
              <div className="leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium transform translate-y-1/2">
                Already have an account?
                <Link
                  to="/login"
                  className=" underline cursor-pointer text-primary-400 ml-2"
                >
                  Sign In
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Registration;
